<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('elearning_accommodation.check_in_out_report') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
              <b-row>
                    <div class="col-5">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="check_in"
                      >
                        <template v-slot:label>
                          {{$t('elearning_accommodation.check_in')}}
                        </template>
                        <b-form-input
                            class="fromDate"
                            v-model="search.check_in"
                            :placeholder="$t('globalTrans.select_date')"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-5">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="check_out"
                        >
                          <template v-slot:label>
                            {{$t('elearning_accommodation.check_out')}}
                          </template>
                          <b-form-input
                              class="fromDate"
                              v-model="search.check_out"
                              :placeholder="$t('globalTrans.select_date')"
                          ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-2">
                          <b-button type="submit" variant="primary" class="mr-2"  @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                        </div>
                  </b-row>
            </template>
        </iq-card>
        <b-row>
          <b-col md="12" v-if="showData">
            <b-overlay>
              <b-row v-if='datas.length > 0'>
                <b-col md="12">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('elearning_accommodation.check_in_out_report') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <b-button class="btn_add_new" @click="pdfExport">
                        <i class="fas fa-print"></i>{{ $t('globalTrans.print') }}
                      </b-button>
                    </template>
                    <template v-slot:body>
                      <b-overlay>
                        <b-row mt-5>
                          <b-col md="12" class="table-responsive">
                            <div style="border: 2px solid;margin:10px;padding:10px">
                              <b-row>
                                <b-col>
                                  <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                                    <template v-slot:projectNameSlot>
                                      {{ }}
                                    </template>
                                    {{ $t('elearning_accommodation.check_in_out_report') }}
                                  </list-report-head>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col>
                                  <b-table-simple thead-class="bg-primary" class="tg mt-3" bordered hover small caption-top responsive v-if="datas.length > 0">
                                    <b-thead>
                                      <b-tr>
                                        <b-th style="width:7%" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                        <b-th style="width:7%" class="text-center">{{ $t('elearning_config.fiscal_year') }}</b-th>
                                        <b-th style="width:10%" class="text-center">{{ $t('org_pro.organization') }}</b-th>
                                        <b-th style="width:10%" class="text-center">{{ $t('org_pro.office_type') }}</b-th>
                                        <b-th class="text-center">{{$t('org_pro.office_namel')}}</b-th>
                                        <b-th class="text-center">{{$t('elearning_accommodation.guest_name')}}</b-th>
                                        <b-th class="text-center">{{$t('globalTrans.mobile')}}</b-th>
                                        <b-th class="text-center">{{$t('elearning_accommodation.working_org')}}</b-th>
                                        <b-th class="text-center">{{$t('elearning_accommodation.check_in')}}</b-th>
                                        <b-th class="text-center">{{$t('elearning_accommodation.check_out')}}</b-th>
                                        <b-th class="text-center">{{$t('elearning_accommodation.total_amount')}}</b-th>
                                      </b-tr>
                                    </b-thead>
                                    <b-tbody v-for="(info, index) in datas" :key="index">
                                      <b-td class="text-center">{{ $n(index + 1, { useGrouping: false }) }}</b-td>
                                      <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.fiscal_year_bn : info.fiscal_year }}</b-td>
                                      <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.org_bn : info.org }}</b-td>
                                      <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.office_type_bn : info.office_type }}</b-td>
                                      <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.office_bn : info.office }}</b-td>
                                      <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.accommodation.guest_name_bn : info.accommodation.guest_name }}</b-td>
                                      <b-td class="text-center">{{  info.accommodation.mobile }}</b-td>
                                      <b-td class="text-center"><slot v-if="info.accommodation.guest_org_id === 999">
                                              {{info.accommodation.guest_org_name}}
                                            </slot>
                                            <slot v-else>
                                              {{ $i18n.locale === 'bn' ? info.guest_org_bn : info.guest_org }}
                                            </slot></b-td>
                                      <b-td class="text-center">{{ info.accommodation.check_in | dateFormat }}</b-td>
                                      <b-td class="text-center">{{ info.accommodation.check_out | dateFormat }}</b-td>
                                      <b-td class="text-center">{{ $n(info.total_amount) }}</b-td>
                                    </b-tbody>
                                  </b-table-simple>
                                </b-col>
                              </b-row>
                            </div>
                          </b-col>
                        </b-row>
                      </b-overlay>
                    </template>
                  </iq-card>
                </b-col>
              </b-row>
              <div class="panel-body text-center mt-3" v-else>
                <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
              </div>
            </b-overlay>
          </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { billGenerateList } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
    props: ['id'],
    components: {
        ListReportHead
    },
    created () {
      this.search = Object.assign({}, this.search, {
        org_id: this.$store.state.dataFilters.orgId,
        fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
      })
    },
    data () {
        return {
          saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
          search: {
            org_id: 0,
              room_type_id: 0,
              room_id: 0,
              guest_house_id: 0,
              check_in: '',
              check_out: ''
          },
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          datas: [],
          loading: false,
          showData: false
        }
    },
    mounted () {
        core.index()
        flatpickr('.fromDate', {})
    },
    computed: {
      i18 () {
        return this.$i18n.locale
      }
    },
    methods: {
      pdfExport () {
          const reportTitle = this.$t('elearning_accommodation.check_in_out_report')
          ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.datas, this, this.search)
      },
      onChangeFile (e) {
          this.attachmentDemo = e.target.files[0]
      },
      getEditingData () {
          const tmpData = this.$store.state.list.find(item => item.id === this.id)
          return JSON.parse(JSON.stringify(tmpData))
      },
      async searchData () {
        this.loading = true
        this.showData = true

        const result = await RestApi.getData(trainingElearningServiceBaseUrl, billGenerateList, this.search)
        if (result.success) {
          this.loading = false
          if (result.data) {
            this.datas = this.getCustomDataList(result.data.data)
          }
        } else {
          this.datas = []
          this.loading = false
        }
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.accommodation.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }

          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.accommodation.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.accommodation.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.accommodation.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }
          const guestOrgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.accommodation.guest_org_id))
          const guestOrgData = {}
          if (typeof guestOrgObj !== 'undefined') {
            guestOrgData.guest_org = guestOrgObj.text_en
            guestOrgData.guest_org_bn = guestOrgObj.text_bn
          } else {
            guestOrgData.guest_org = ''
            guestOrgData.guest_org_bn = ''
          }
          return Object.assign({}, item, orgData, officeData, fiscalYearData, OfficeTypeData, guestOrgData)
        })
        return listData
      }
    }
}
</script>
